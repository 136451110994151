import Layout from "layouts/Layout.vue"
import InnerLayout from "layouts/InnerLayout.vue"
import { Home, About, Login, Logout, Registration, NotFound } from "components"
import { Features, Pricing } from "components"

export default [
    {
        path: "/",
        name: "Outer",
        redirect: "/",
        component: Layout,
        children: [
            {
                path: "/",
                name: "Home",
                component: Home,
                meta: {
                    title: 'Home'
                },
            },
            {
                path: "/features",
                name: "Features",
                component: Features,
                meta: {
                    title: 'Features'
                },
            },
            {
                path: "/pricing",
                name: "Pricing",
                component: Pricing,
                meta: {
                    title: 'Pricing'
                },
            },
            {
                path: "/about",
                name: "About",
                component: About,
                meta: {
                    title: 'About'
                },
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: 'Login'
        },
    },
    {
        path: "/forgot",
        name: "Forgot",
        component: Login,
        meta: {
            title: 'Forgot password',
        },
    },
    {
        path: "/registration",
        name: "Registration",
        component: Registration,
	beforeEnter(to, from, next) {
	    next((vm) => {
		vm.initializeTurnstile(); // initialize turnstile when entering the route
	    });
	},
        meta: {
            title: 'Registration'
        },
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            title: '404'
        }
    }
]
